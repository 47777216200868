var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx } from "react/jsx-runtime";
// @mui
import { TableRow, TableCell } from '@mui/material';
//
import EmptyContent from '../empty-content';
export default function TableNoData(_a) {
    var isNotFound = _a.isNotFound;
    return (_jsx(TableRow, { children: isNotFound ? (_jsx(TableCell, __assign({ colSpan: 12 }, { children: _jsx(EmptyContent, { title: "No Data", sx: {
                    '& span.MuiBox-root': { height: 160 },
                } }) }))) : (_jsx(TableCell, { colSpan: 12, sx: { p: 0 } })) }));
}
