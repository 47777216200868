var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx } from "react/jsx-runtime";
// @mui
import { TableRow, TableCell } from '@mui/material';
export default function TableEmptyRows(_a) {
    var emptyRows = _a.emptyRows, height = _a.height;
    if (!emptyRows) {
        return null;
    }
    return (_jsx(TableRow, __assign({ sx: __assign({}, (height && {
            height: height * emptyRows,
        })) }, { children: _jsx(TableCell, { colSpan: 9 }) })));
}
